/* istanbul ignore file */

import { Injectable } from '@angular/core';
import {
  Agreement,
  AgreementQueryResourceService,
  FundingAppealCommandResourceService,
  FundingAppealQueryResourceService,
  FundingAppealRequest,
  PaymentIdentityQueryResourceService,
  RequestPartFundingAppealsFiltersExtendPageable
} from '../../../shared/api';
import ModeEnum = Agreement.ModeEnum;

@Injectable()
export class FundingAppealsService {
  constructor(
    private agreementQueryResourceService: AgreementQueryResourceService,
    private fundingAppealCommandResourceService: FundingAppealCommandResourceService,
    private fundingAppealQueryResourceService: FundingAppealQueryResourceService,
    private paymentIdentityQueryResourceService: PaymentIdentityQueryResourceService
  ) {}

  public async refreshAgreementAndInitFundingAppeals(siteId: string) {
    const agreementResponse = await this.agreementQueryResourceService.getLatestAgreement(siteId).toPromise();
    const fundingAppealsResponse = await this.fundingAppealQueryResourceService.initializeFA(siteId).toPromise();

    return {
      mode: agreementResponse.mode,
      initData: { ...(fundingAppealsResponse as any) }
    };
  }

  public async hasFundingAppeals(siteId: string) {
    return await this.fundingAppealQueryResourceService.checkFundingAppealExist(siteId).toPromise();
  }

  public async refreshPaymentIdentity(siteId) {
    const paymentResponse = await this.paymentIdentityQueryResourceService.getPaymentIdentity(siteId).toPromise();
    return paymentResponse;
  }

  public async refreshClosing(siteId) {
    const closingData = await this.fundingAppealQueryResourceService.getFundingAppealClosing(siteId).toPromise();
    return {
      amountWithTax: closingData.amountWithTaxes,
      percent: closingData.percent,
      amount: closingData.amount
    };
  }

  public sendBill(importedBills, siteId, num) {
    for (const item of importedBills) {
      this.fundingAppealCommandResourceService.addImportedBills(siteId, num, item.billNumber, item.bill).toPromise();
    }
  }

  public sendAppendix(appendix, siteId, fundingAppealItem) {
    this.fundingAppealCommandResourceService.addImportedAppendix(siteId, fundingAppealItem, appendix).toPromise();
  }

  public async saveFundingAppeals(
    siteId: string,
    dryRun: boolean,
    isClosing: boolean,
    fundingAppealRequest?: FundingAppealRequest
  ) {
    return await this.fundingAppealCommandResourceService
      .addFundingAppeal(siteId, dryRun, isClosing, fundingAppealRequest)
      .toPromise();
  }

  public async calculateItems(siteId: string, fundingAppealRequest?: FundingAppealRequest) {
    return await this.fundingAppealQueryResourceService.calculateItems(siteId, fundingAppealRequest).toPromise();
  }
}
